@import "../../global.scss";

.portfolioList{
    font-size: 14px;
    margin: 20px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;

    &.active{
        background: $mainColor;
        color: white;
    }
}
