@import "../../global.scss";

.navbar{

    width: 100%;
    height: 70px;
    color: $mainColor ;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 10;

    .wrapper{
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        .left{

            display: flex;
            align-items: center;

            .logo{
                font-size: 30px;
                font-weight: 700;
                text-decoration: none ;
                color: inherit;
                margin-right: 40px;
            }

            .itemContainer{
                display: flex;
                align-items: center;
                margin-right: 10px;
                color: inherit;
                text-decoration: inherit;
            }

            .icon{
                font-size: 25px;
            }
        }

        .right{
            font-size: 30px;
            overflow: visible;
            display: flex;


            .writing{
                margin-right: 25px;
                font-size: 16px;
                font-weight: 500;
            }

            .hamburger{

                width: 32px;
                height: 25px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: space-between;



                span{
                    width: 100%;
                    height: 3px;
                    background: $mainColor;
                    transform-origin: left;
                    transition: all 1s ease;
                }
            }
        }

    }

    &.active{
        background-color: $mainColor;
        color: white;

        .hamburger{

            width: auto;
            overflow: visible;
            z-index: 2;

            span{
                &:first-child{
                    background: white;
                    transform: rotate(45deg);


                }

                &:last-child{
                    background-color: white;
                    transform: rotate(-45deg);



                }

                &:nth-child(2){
                    opacity: 0;
                }
            }
        }
    }
}
