@import "../../global.scss";

.portfolio{
    background:white;
    display: flex;
    flex-direction: column;
    align-items: center;


    h1{
        font-size: 50px;
        padding: 0;
        list-style: none;
        display: flex;
    }

    ul{
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;


        .item{
            width: 220px;
            height: 150px;
            border: 20px;
            border: 1px solid rgb(218, 215, 215);
            margin: 10px 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            color: black;

            :hover{
                background: $mainColor;
                color: white;
            }

            a{
                display: flex;
                height: 100%;
                width: 100%;
                text-decoration: none;
                color: inherit;
                justify-content: center;
                align-items: center;

                h3{
                    text-align: center;
                    justify-self: center;
                    align-self: center;
                }
            }





        }
    }
}
