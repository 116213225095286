@import "../../global.scss";

.intro{
    background-color: white;
    display: flex;
    flex-wrap: wrap;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    .right{
        flex: 0.5;
        background: white;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;


        .wrapper{
            padding: 0;
            font-size: 15px;
            font-weight: 500;
            overflow: hidden;
            color: white;
        }


        .square1{
            width: 80%;
            height: 32%;
            background-color: darkgray;
            align-self: flex-start;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border-color: $mainColor;
        }

        .square2{
            width: 80%;
            height: 32%;
            align-self: flex-end;
            background: skyblue;
            float: right;
            display: flex;
            justify-content: center;
            align-items: center;
        }


        .square3{
            width: 80%;
            height: 30%;
            align-self: flex-start;
            background:darksalmon;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;

        }

    }


    .left{
        flex: 1;
        position: relative;

        .wrapper{
            height: 100%;
            width: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;


            @include mobile{
                padding-left: 5px;
                align-items: center;
            }

            h1{
                font-size: 60px;
                margin: 10px 0;

                @include mobile{
                    font-size: 40px;

                }
            }
            h2{
                font-size: 35px;

            }
            h3{
                font-size: 30px;
                @include mobile{
                    font-size: 20px;

                }


                span{
                    font-size: inherit;
                    color: crimson
                }

                .ityped-cursor{
                    animation: blink 1s infinite;
                }

                @keyframes blink{
                    50%{
                        opacity: 1;
                    }
                    100%{
                        opacity: 0;
                    }
                }
            }
        }
    }



}
