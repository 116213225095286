.app{
    height: 100vh;
    font-weight: 300;
}

.sections{
    width: 100%;
    height: calc(100vh - 70px);
    background-color: lightsalmon;
    position: relative;
    top: 70px;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; //FireFox
    &::-webkit-scrollbar{
        display: none;
    }

    > *{
        width: 100vw;
        height: calc(100vh - 70px);
        scroll-snap-align: start;
    }
}
