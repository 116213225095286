.about{

    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1{
        margin-bottom: 20px;
        font-size: 50px;
    }


    .text-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70%;
        padding-top: 0;




        p{
            width: 100%;
            margin-bottom: 10px;
            text-align: justify;
            font-size: 18px;
            padding-left: 0px;

        }
    }

}
